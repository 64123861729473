<template>
    <div class="mt-3 stage-inner-container-padded">
        <b-row>
            <b-col cols="12">
                <h3>Create or Update Records by CSV - Step 1</h3>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col xl="4" sm="12">
                <b-form-group
                    id="session-type-label"
                    label="What's The Goal?"
                    label-for="session-type"
                >
                    <b-form-radio-group id="session-type" stacked v-model="selectedType" :options="typeOptions"></b-form-radio-group>
                </b-form-group>
            </b-col>
            <b-col xl="4" sm="12">
                <b-form-group
                    id="select-table-label"
                    description="This is the table you are creating or inserting against"
                    label="What Are We Working With?"
                    label-for="select-table"
                >
                    <b-form-select id="select-table" :options="availableTables" v-model="selectedTable" required></b-form-select>
                </b-form-group>
            </b-col>
            <b-col xl="4" sm="12">
                <upload-file
                    acceptsText="CSV"
                    acceptTypes=".csv"
                    @uploadCompleted="fileUploadComplete"
                    :multiple="false"
                ></upload-file>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <b-button-toolbar justify>
                    <b-button variant="warning" @click="$router.go(-1)">Discard Create or Update and Exit</b-button>
                    <b-button variant="success" :disabled="!selectionValid" @click="createSessionAndProceed">Proceed To Next Step</b-button>
                </b-button-toolbar>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

import UploadFileDrop from '@/components/UploadFileDrop.vue'

export default {
    name: 'BulkInsertUpdateStart',
    components:{
        'upload-file': UploadFileDrop
    },
    props:{

    },
    data(){
        return{
            typeOptions:[
                {text: "Create New Records", value: "create"},
                {text: "Update Existing Records", value: "update"}
            ],
            selectedType: null,
            availableTables:[
                { value: null, text: 'Please select a table' },
                {text: "Customers", value: "customers"},
                {text: "Sites", value: "sites"},
                {text: "Connections", value: "connections"},
                {text: "Devices", value: "devices"}
            ],
            selectedTable: null,
            uploadedFileID: null,
        }
    },
    methods:{
        fileUploadComplete(fileID){
            console.log(`File Uploaded: ${fileID}`)
            this.uploadedFileID = fileID;
        },
        createSessionAndProceed(){
            var sessionRequest = {
                table: this.selectedTable,
                type: this.selectedType,
                fileID: this.uploadedFileID
            }
            butils.instance.post(process.env.VUE_APP_API_BASE_URL + `/bulk/session.json`, sessionRequest)
            .then((response)=>{
                var sessionID = response.data.result.sessionID;
                // Move To Stage 2
                this.$router.push({path: `/home/upload/assign/${sessionID}`});
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError404(err)){
                    butils.createToast(this, 'Session Load Failed', 'The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.', 'danger');
                    this.errorMessage = "The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.";
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Disallowed By Endpoint Control', 'Contact your administrator to receive permission to perform file upload', 'warning');
                }else{
                    console.log("An Unknown Error Occured Durring Upload");
                    console.log(err);
                    butils.createToast(this, 'An Error Occured Durring Bulk Session Creation', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        }
    },
    watch:{

    },
    computed:{
        selectionValid: function(){
            if(this.selectedType != null && this.selectedTable != null && this.uploadedFileID != null){
                return true;
            }else{
                return false;
            }
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>